import React,{useState,useEffect} from 'react'
import { Menu } from 'antd';
import { WalletOutlined,CodeOutlined,UserOutlined ,HomeOutlined} from '@ant-design/icons';
import {NavLink, useHistory} from 'react-router-dom'
import { loginUser } from '../../api/cookie';
import userpathsFn from '../../grants'

import './aside.css'

const { SubMenu } = Menu;

const Aside = (props) => {

  const [current,setCurrent] = useState('domainlist');
  const [supper,setSupper] = useState();
  const [normal,setNormal] = useState();
  const history = useHistory();
  
  useEffect(
    ()=>{
      const pathname = history.location.pathname.split('/');
      const path =  pathname[pathname.length - 1];
      setCurrent(path);
      let userInfo = loginUser();
      setSupper(userInfo.level == 0);
      setNormal(userInfo.level == 1);
    }
    
  , [history.location])

  

    return (
      <>    
      <Menu 
        mode='inline'
        theme = 'dark'
       icon= {<HomeOutlined />} 
       title="主页"
       >
      <Menu.Item icon={<HomeOutlined />} key="home" >
                      <NavLink to="/layouts/home" activeClassName="tochange">
                    主页
                      </NavLink>
                    </Menu.Item>
          </Menu> 
        <Menu
          style={{ width: 161 }}
          // defaultOpenKeys={['DM']}
          selectedKeys={current}
          mode="inline"
          theme = 'dark'
        >
          
          
          <SubMenu key="DM" icon={<WalletOutlined />} title="自切管理">
                 {supper && <Menu.Item key="domainlist" >
                      <NavLink to="/layouts/supdomainlist" activeClassName="tochange">
                    域名列表
                      </NavLink>
                    </Menu.Item>}
                    {!supper && <Menu.Item key="subdomainlist" >
                      <NavLink to="/layouts/domainlist" activeClassName="tochange">
                    域名列表
                      </NavLink>
                    </Menu.Item>}
                  <Menu.Item key="domainConfig">
                        <NavLink to="/layouts/domainConfig" activeClassName="tochange">
                    推广配置
                        </NavLink>
                    </Menu.Item>
                    
                   
          </SubMenu>
          {!normal &&
            <SubMenu key='PD' icon={<CodeOutlined />} title="产品管理">
              <Menu.Item key="addProduct">
                        <NavLink to="/layouts/addProduct" activeClassName="tochange">
                    产品列表
                        </NavLink>
                    </Menu.Item>
            </SubMenu> 
          }
          { supper && <>
            
            <SubMenu key="PM" icon= {<CodeOutlined />} title="像素管理">
              <Menu.Item key="pixellist">
                  <NavLink to="/layouts/pixellist" activeClassName="tochange">
                    像素列表
                  </NavLink>
                </Menu.Item>
            </SubMenu> 
            <SubMenu key="UM" icon= {<UserOutlined />} title="用户管理">
            <Menu.Item key="userManage">
                <NavLink to="/layouts/userlist" activeClassName="tochange">
                  用户列表
                </NavLink>
              </Menu.Item>
          </SubMenu> 
        </>
        } 
        </Menu>
      </>
    );
  }


export default Aside ;