//该模块封装各种请求
import service from '../utils/service'

//post请求
export function post(url,params,headers){
    return new Promise((resolve,reject)=>{
        service.post(url,params,headers)
        .then(res=>{
            if(res.data){
                resolve(res.data)
            }else{
                resolve(res)
            }
        },err=>{
            reject(err)
        })
        .catch(err=>{
            reject(err)
        })
    })
}

//get请求
export function get(url,params){

    //拼接请求参数,请求参数默认为对象
    let queryStr = '';
    if(params){
        let arr = [];
        for(var key in params){
            if(params[key]){
                //如果有值
                arr.push(`${key}=${params[key]}`)
            }
        }
        queryStr = arr.join('&')
    }
    let path = queryStr ? url+"?"+queryStr : url;
    return new Promise((resolve,reject)=>{
        service.get(path)
        .then(res=>{
            if(!res.data){
                resolve(res);
            }else{
                resolve(res.data);
            }
        },err=>{
            reject(err);
        })
        .catch(err=>{
            reject(err);
        });
    });
}

//delete请求
export function remove(url,id){
    return new Promise(function(resovle,reject){
        service.delete(url+"/"+id)
        .then(res=>{
            if(!res.data){
                resovle(res);
            }
            resovle(res.data);
        },err=>{
            reject(err);
        })
        .catch(err=>{
            reject(err);
        });
    });
}

//put请求
export function update(url,id,data){
    return new Promise(function(resovle,reject){
        service.put(url+"/"+id,data)
        .then(res=>{
            if(!res.data){
                resovle(res);
            }
            resovle(res.data);
        },err=>{
            reject(err);
        })
        .catch(err=>{
            reject(err);
        });
    });
}





//拼接请求参数
function queryStrFn(obj){
    let queryStr = '';
    let arr = [];
    for(var key in obj){
        if(obj[key]){
            //如果有值
            arr.push(`${key}=${obj[key]}`)
        }else{
            return "username=12"
        }
    }
    return arr.join('&')
}