import React, {useState ,useEffect} from "react";
import "./pixellist.css";
import {  Button, Table, Modal, Input,Form,message} from "antd";
import TextArea from 'antd/lib/input/TextArea';
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { getPixelList,removePixel ,addPixel} from "../../api/pixellist";
// import  AddPixelForm  from "./addPiexl";

const PixelList = () =>  {


    const [form] = Form.useForm();
    
    
    const [data, setData] = useState([]);

    const [queryParams, setQueryParams] = useState({
        fbNum: "",
        current: 1,
        size: 10,
    });
    const [total, setTotal] = useState(0);

    const [modelData,setModelData] = useState({
        visible:false,
        loading: true,
        itemData:null,
        isEdit: true
    });

    

    const columns =[
        { title: "序号", dataIndex: "id",},
        {title: "FB编号",
            dataIndex: "fbNum",
            render: (text, record, index) => (
                <>
                    <a onClick={() => showDetail(record)}> {text}</a>
                </>
            ),
        },
        { title: "访问地址", dataIndex: "urlPath"},
        {  title: "创建时间", dataIndex: "createTime",},
        { title: "操作", key: "action",
            render: (text, record, index) => (
                <>
                    <Button style={{marginLeft:10}} type="primary" onClick={() => showConfirm(index,(idx)=>removeData(idx),()=>destroyAll())}>
                        删除
                    </Button>
                </>
            ),
        },
    ]

   
           
    useEffect(() => {
        getAllData(1);
    }, []);       
        
 //获取所有数据
    const getAllData = (current = queryParams.current, size = queryParams.size) => {
        getPixelList({
            current: current,
            size: size,
            fbNum: queryParams.fbNum,
        }).then((res) => {
            
        
            res.data.list.forEach((item, idx) => {
                item.key = idx;
            });
            setData(res.data.list)
            setTotal(Number(res.data.total))
        
        });
    };
    


    const removeData = (idx) => {
        //删除
        console.log("remove里的idx", idx);
        removePixel(data[idx].id).then((data) => {
            getAllData();
        });
    };

    const showConfirm = (idx,removeDataCall,destroyAllCall) => {
        // let that = this;
        const { confirm } = Modal;
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: <p>确定删除吗？</p>,
            onOk() {
                removeDataCall(idx);
            },
            onCancel() {
                destroyAllCall();
            },
        });
    };
     //销毁确认框
    const destroyAll = () => {
        Modal.destroyAll();
    };   
    
    const showDetail = (record)=>{
        setModelData({
            ...modelData,
            isEdit: false,
            visible:true
        })
        form.setFieldsValue({
            fbNum: record.fbNum,
            pixelCode: record.pixelCode,
        });
    }
    

    

    //双向绑定（查询条件）
    const changeFind = (ev) => {
        setQueryParams({
            ...queryParams,
            [ev.target.name]: ev.target.value,
        })
    };


    

   

    const showModal = () => {
        setModelData({
                ...modelData,
                isEdit:true,
                visible:true
            
        })
        console.log("showmodal----")
    };
    const handleCancel = () => {
        form.resetFields();
        setModelData({
                ...modelData,
                visible:false
        })
        console.log("cancal  "+ modelData.itemData);

    };
    const handleOk = () => {
        setModelData({
                ...modelData,
                visible:false
        })
        getAllData();
    };
   
    const onFinish = (values) => {

        addPixel(values).then((data)=>{
                
          if(data.code !== 0){
              message.error(data.msg);
          }else{
              form.resetFields()
              message.info('添加成功');
              handleOk();
          }
        })
       
        console.log('Success:', values);
      };

    return (
        
        <div className="pixellist">
            
            <Modal
                visible={modelData.visible}
                title={modelData.isEdit ? "添加像素" : "像素详情"}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                >
            <Form form={form} name="pixel" labelCol={{span: 4}}wrapperCol={{ span: 18,}} initialValues={ modelData.data}
                onFinish={onFinish} autoComplete="off"
                >
                
                <Form.Item label="FB 编 号" name="fbNum" rules={[{required: true, message: '请输入FB编号!',}, ]} >
                    <Input disabled={!modelData.isEdit} placeholder='请输入FB编号'/>
                </Form.Item>

                <Form.Item label="像素代码" name="pixelCode" rules={[{required: true,message: '请输入像素代码!',}, ]}>
                    <TextArea disabled={!modelData.isEdit} style={{height:150}}  placeholder='请输入像素代码'/>
                </Form.Item>
                
                {modelData.isEdit &&
                    <Form.Item 
                    wrapperCol={{
                        offset: 18,
                        span: 16,
                    }}
                    >
                    <Button type="primary"  htmlType="submit">
                        保存
                    </Button>
                    </Form.Item>
                }
                </Form>
                
            </Modal>

            <div className="list_title">
                <h3>PIXEL列表</h3>
                <input
                    type="text"
                    name="fbNum"
                    value={queryParams.fbNum}
                    onChange={changeFind}
                    id="sear_fbnum"
                    placeholder="请输入FB编号"
                />

                <Button className="sear" onClick={()=>getAllData()}>
                    搜索
                </Button>
                <Button type="primary" className="xz" onClick={()=>showModal()}>
                    新增
                </Button>
                
            </div>
            
            <Table
                rowKey="id"
                columns={columns}
                dataSource={data}
                bordered
                pagination={{
                    current: queryParams.current,
                    pageSize: queryParams.size,
                    total: total,
                    pageSizeOptions: ["10", "30", "50", "100"],
                    showSizeChanger: true,
                    showQuickJumper: true,
                    showTotal: (total) => `共 ${total} 条记录`,
                }}
                onChange={(pagination, sorter, extra)  => {
                    if (
                        pagination.current === queryParams.current &&
                        pagination.pageSize ===queryParams.size
                    )
                        return;
                    setQueryParams( { ...queryParams, current: pagination.current,size: pagination.pageSize});
                    getAllData(pagination.current, pagination.pageSize);
                }}
                scroll={{ x: 'max-content', y: 600 }}
            />

        </div>
    );
}

export default PixelList;
