import React, {useState ,useEffect} from "react";
import "./userlist.css";
import {  Button, Table, Modal, Input,Form,message, Select} from "antd";
import TextArea from 'antd/lib/input/TextArea';
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { pageUser,addUser ,updateUser,removeUser} from "../../api/userlist";

const UserList = () =>  {


    const [form] = Form.useForm();
    
    
    const [data, setData] = useState([]);

    const [queryParams, setQueryParams] = useState({
        username: "",
        current: 1,
        size: 10,
    });
    const [total, setTotal] = useState(0);

    const [modelData,setModelData] = useState({
        visible:false,
        loading: true,
        itemData:null,
        isEdit: true
    });

    

    const columns =[
        { title: "序号", dataIndex: "id",},
        { title: "账号名称",dataIndex: "username",},
        { title: "用户昵称", dataIndex: "nickname"},
        { title: "用户类型", dataIndex: "level",
            render: (text, record, index) => {
                switch(text){
                    case 'ADMIN':
                        return '管理员';
                    case 'SUB_ADMIN':
                        return '子管理员';
                    default:
                        return '普通用户';
                }
            }
        },
        { title: "登录密码", dataIndex: "showPassword"},
        {  title: "创建时间", dataIndex: "createTime",},
        { title: "操作", key: "action",
            render: (text, record, index) => (
                <>
                    <Button style={{color:'#fff',  backgroundColor: '#5cb85c' ,borderColor: '#4cae4c' ,width:'70px' }} type="default" 
                        onClick={() => showModal(record)} >
                        修改
                    </Button>
                    { record.username != 'admin' && 
                    <Button style={{marginLeft:10}} type="primary" onClick={() => showConfirm(index,(idx)=>removeData(idx),()=>destroyAll())}>
                        删除
                    </Button>
                    }
                </>
           
            ),
        },
    ]

   
           
    useEffect(() => {
        getAllData(1);
    }, []);       
        
 //获取所有数据
    const getAllData = (current = queryParams.current, size = queryParams.size) => {
        pageUser({
            current: current,
            size: size,
            username: queryParams.username,
        }).then((res) => {
            
        
            res.data.list.forEach((item, idx) => {
                item.key = idx;
            });
            setData(res.data.list)
            setTotal(Number(res.data.total))
        
        });
    };
    


    const removeData = (idx) => {
        //删除
        console.log("remove里的idx", idx);
        removeUser(data[idx].id).then((data) => {
            getAllData();
        });
    };

    const showConfirm = (idx,removeDataCall,destroyAllCall) => {
        // let that = this;
        const { confirm } = Modal;
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: <p>确定删除吗？</p>,
            onOk() {
                removeDataCall(idx);
            },
            onCancel() {
                destroyAllCall();
            },
        });
    };
        
    
    const showDetail = (record)=>{
        setModelData({
            ...modelData,
            isEdit: false,
            visible:true
        })
        form.setFieldsValue({
            fbNum: record.fbNum,
            pixelCode: record.pixelCode,
        });
    }
    

    

    //双向绑定（查询条件）
    const changeFind = (ev) => {
        setQueryParams({
            ...queryParams,
            [ev.target.name]: ev.target.value,
        })
    };


    //销毁确认框
    const destroyAll = () => {
        Modal.destroyAll();
    };

   

    const showModal = (data) => {
        setModelData({
            ...modelData,
            isEdit: data ? true : false,
            visible:true
        })
        if(data){
            form.setFieldsValue({
                id: data.id,
                username: data.username,
                nickname: data.nickname,
                showPassword: data.showPassword,
                level: data.level
            });
        }

        
    };
    const handleCancel = () => {
        form.resetFields();
        setModelData({
                ...modelData,
                visible:false
        })
        console.log("cancal  "+ modelData.itemData);

    };
    const handleOk = () => {
        setModelData({
                ...modelData,
                visible:false
        })
        getAllData();
    };
   
    const onFinish = (values) => {
        if(values.id){
            updateUser(values).then((data)=>{
                
                if(data.code !== 0){
                    message.error(data.msg);
                }else{
                    form.resetFields()
                    message.info('修改成功');
                    handleOk();
                }
              })
        }else{
            addUser(values).then((data)=>{
                
                if(data.code !== 0){
                    message.error(data.msg);
                }else{
                    form.resetFields()
                    message.info('添加成功');
                    handleOk();
                }
              })
        }
        console.log('Success:', values);
      };

    return (
        
        <div className="pixellist">
            
            <Modal
                visible={modelData.visible}
                title={modelData.isEdit ?  "修改用户" : "添加用户"}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                >
            <Form form={form} name="userList" labelCol={{span: 6}}wrapperCol={{ span: 16,}} 
                onFinish={onFinish} autoComplete="off"
                >
                <Form.Item hidden label="id" name="id" rules={[{required: false, message: '请输入账号名称!',}, ]} >
                    <Input  />
                </Form.Item>
                <Form.Item label="登录账号" name="username" rules={[{required: true, message: '请输入账号名称!',}, ]} >
                    <Input  placeholder='请输入账号名称'/>
                </Form.Item>
                <Form.Item label="用户昵称" name="nickname" rules={[{required: true, message: '请输入用户昵称!',}, ]} >
                    <Input  placeholder='请输入用户昵称'/>
                </Form.Item>

                <Form.Item label="登录密码" name="showPassword" rules={[{required: true, message: '请输入登录密码!',}, ]} >
                    <Input  placeholder='请输入登录密码'/>
                </Form.Item>
                <Form.Item label="用户类型" name="level" rules={[{required: true, message: '请选择用户类型!',}, ]} >
                    <Select
                        
                        placeholder="请选择用户类型"
                        options={[
                            { value: 'ADMIN', label: '管理员' },
                            { value: 'SUB_ADMIN', label: '子管理员' },
                            { value: 'NORMAL', label: '普通用户' },
                        ]}
                    />
                </Form.Item>
                
                
                    <Form.Item 
                    wrapperCol={{
                        offset: 18,
                        span: 16,
                    }}
                    >
                    <Button type="primary"  htmlType="submit">
                        保存
                    </Button>
                    </Form.Item>
                
                </Form>
                
            </Modal>

            <div className="list_title">
                <h3>用户列表</h3>
                <input
                    type="text"
                    name="username"
                    value={queryParams.username}
                    onChange={changeFind}
                    id="sear_fbnum"
                    placeholder="请输入用户名"
                />

                <button className="sear" onClick={()=>getAllData()}>
                    搜索
                </button>
                <button type="primary" className="xz" onClick={()=>showModal()}>
                    新增
                </button>
                
            </div>
            
            <Table
                rowKey="id"
                columns={columns}
                dataSource={data}
                bordered
                pagination={{
                    current: queryParams.current,
                    pageSize: queryParams.size,
                    total: total,
                    pageSizeOptions: ["10", "30", "50", "100"],
                    showSizeChanger: true,
                    showQuickJumper: true,
                    showTotal: (total) => `共 ${total} 条记录`,
                }}
                onChange={(pagination, sorter, extra)  => {
                    if (
                        pagination.current === queryParams.current &&
                        pagination.pageSize ===queryParams.size
                    )
                        return;
                    setQueryParams( { ...queryParams, current: pagination.current,size: pagination.pageSize});
                    getAllData(pagination.current, pagination.pageSize);
                }}
                scroll={{ x: 'max-content', y: 600 }}
            />

        </div>
    );
}

export default UserList;
