import React from 'react'
import './header.css'
import {Link} from 'react-router-dom'
import { Button, Badge, Dropdown, Menu, Modal } from 'antd'
import { MenuFoldOutlined,MenuUnfoldOutlined } from '@ant-design/icons';
import { loginUser } from '../../api/cookie'
import { logoutCookie } from '../../api/cookie'
import { logout } from '../../api/login'

export default class HeaderBar extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            nickname:""
        }
    }

    componentDidMount(){
        let nickname = localStorage.getItem('nickname') ? localStorage.getItem('nickname') : (loginUser() ? loginUser().nickname : '');
        this.setState({
            nickname:nickname?nickname:"当前未登录账户"
        })
    }

    //退出当前账户
    toLogin=()=>{
        logoutCookie();
        localStorage.removeItem('nickname');
        logout();

    }



    render(){
        return (
            <div className="header_nav">
                <div className="h2">
                    推广后台管理系统
                </div>
                
                <div className="welcoms">
                    <div className="wel_top" >
                        <div className="ttp">
                            欢迎您: {this.state.nickname}
                        </div>   
                           
                    </div>
                    <div className='link' >
                        <Link to="/login" onClick={this.toLogin}>
                            退出登录
                        </Link>
                    </div>   
                </div>
                
                    
            </div>
                
        )
    }
}
