import Layouts from './layouts'
import React from 'react'
import 'antd/dist/antd.min.css';
import {Provider,connect} from 'react-redux'
import {BrowserRouter,Route,Switch,Redirect,withRouter} from 'react-router-dom'
import Login from './pages/login/login'
import store from './store'
import { Space,Spin  } from 'antd';
import userpaths from './grants'
import './App.css';


class App extends React.Component {
  constructor(props){
    super(props);  
    this.state={
        isshowAdduser:false,
        isload:store.getState().isload,
    } 
  }

  fn=(str)=>{
    console.log('传来的str:',str);
      this.setState({
        isshowAdduser:str
      });
  }

  render = () => (
    <div className="App">
      <BrowserRouter>
        <Provider store={store} >
          <Spin size="large" className="loading" spinning={false}/>
          <Switch>
              <Redirect exact={true} from="/" to="/layouts"></Redirect>
              <Route path="/layouts" component={Layouts}></Route>
              <Route path="/login" component={Login}></Route>
          </Switch> 
        </Provider>
      </BrowserRouter>
    </div>
  )
}


export default App

