import {get,post,remove} from './index'



//新增
export function addPixel(params){
    //无参数，查询所有数据 ,有参则按照参数查找
    return post('/pixel/add',params)
}

//查询
export function getPixelList(params){
    //无参数，查询所有数据 ,有参则按照参数查找
    return get('/pixel/page',params)
}

//删除(通过id删除)
export function removePixel(params) {
    //params  要删除数据的id
    return remove('/pixel/delete',params)
}



