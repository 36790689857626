import {get,post,remove,update} from './index'


//查询
//新增
export function addUser(params){
    //无参数，查询所有数据 ,有参则按照参数查找
    return post('/admin/user/add',params)
}

export function updateUser(params){
    //无参数，查询所有数据 ,有参则按照参数查找
    return post('/admin/user/update',params)
}

//查询
export function pageUser(params){
    //无参数，查询所有数据 ,有参则按照参数查找
    return get('/admin/user/page',params)
}

export function listUserAll(){
    //无参数，查询所有数据 ,有参则按照参数查找
    return get('/admin/user/listAll')
}

//删除(通过id删除)
export function removeUser(params) {
    //params  要删除数据的id
    return remove('/admin/user/delete',params)
}

