import axios from 'axios'
import store from '../store'
// import {SHOWLOAD} from '../store/actions'
import { loginUser } from '../api/cookie'
import { message } from 'antd';


const service = axios;
service.defaults.baseURL = '/admin';
service.defaults.timeout = 20000;
service.defaults.withCredentials = true;


export const SHOWLOAD = (payload)=>({
    type:"SHOWLOAD",
    payload
})

//请求拦截器
service.interceptors.request.use(config=>{

    store.dispatch(SHOWLOAD("true"))
    let userInfo = loginUser();
    
    return config;
},(err)=>{
    //请求错误的回调
    return Promise.reject(err);
});



//响应拦截器
service.interceptors.response.use(res=>{
    store.dispatch(SHOWLOAD("false"))
    console.log('响应拦截器',store.getState().isload);
    if(res.data.code == 1003){
        window.location.href = '/Login'
    }
    return res
},(err)=>{
    message.error('服务器异常');
    return Promise.reject(err);
});


export default service;