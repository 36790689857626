import React, { useState, useEffect } from 'react';
import './addProduct.css'
import {Modal, Row, Col, Select,Button, Input, Table, Form,Spin, message, Upload } from 'antd';
import Draggable from 'react-draggable';
import {  ExclamationCircleOutlined, PlusOutlined} from '@ant-design/icons';
import { addProduct,loadTempCategory,loadTempByCate,loadProductByTemp,getProductList,removeProdcut} from '../../api/domainConfig'
import { fromByteArray } from 'ipaddr.js';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { array } from 'bfj/src/events';




const AddProduct = (props) => {

    const { Option } = Select;
    const [form] = Form.useForm()
    const [adForm] = Form.useForm();
    const [tempCates,setTempCates] = useState([]);
    const [tempNames,setTempNames] = useState([]);
    const [cateId,setCateId] = useState();
    const [tmpId,setTmpId] = useState();
    const [pdtName,setPdtName] = useState();
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [data, setData] = useState([]);

    const [queryParams, setQueryParams] = useState({
        current: 1,
        size: 10,
    });
    const [total, setTotal] = useState(0);
    const [addModalVisible,setAddModalVisible] = useState(false);
    const [modelData,setModelData] = useState({
        visible:false,
    });


    useEffect(() => {
        loadTempCategory().then(res=>{
             setTempCates(res.data);
        });
        getAllData(1);
      }, []);

    
      const columns =[
        { title: "序号", dataIndex: "id",render:(text, record, index) => (<><p style={{width:'50px'}}>{text}</p></>)},
        {title: "类目", dataIndex: "cateName",render:(text, record, index) => (<><p style={{width:'50px'}}>{text}</p></>)},
        { title: "模板名称", dataIndex: "tempName"},
        { title: "产品名称", dataIndex: "pdtName",},
        { title: "图片地址", dataIndex: "orginalImgUrl", },
        { title: "图片", dataIndex: "imgUrl",
            render: (text, record, index) => (
                <>
                    <img
                        alt="无法显示"
                        style={{
                            width: '40px',
                        }}
                        src={record.imgUrl}
                        onClick={()=>showImg(record)}
                        />
                </>
            ),
        },
        { title: "操作", key: "action",
            render: (text, record, index) => (
                <>
                    <Button style={{marginLeft:10}} type="primary" onClick={() => showConfirm(index,(idx)=>removeData(idx),()=>destroyAll())}>
                        删除
                    </Button>
                </>
            ),
        },
    ]


    const getAllData = (current = queryParams.current, size = queryParams.size,categoryId = cateId,tempId = tmpId) => {
        getProductList({
            current: current,
            size: size,
            cateId: categoryId,
            tempId: tempId,
        }).then((res) => {
            res.data.list.forEach((item, idx) => {
                item.key = idx;
            });
            setData(res.data.list)
            setTotal(Number(res.data.total))
        
        });
    };

    const removeData = (idx) => {
        //删除
        console.log("remove里的idx", idx);
        removeProdcut(data[idx].id).then((data) => {
            getAllData();
        });
    };

    const showConfirm = (idx,removeDataCall,destroyAllCall) => {
        // let that = this;
        const { confirm } = Modal;
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: <p>确定删除吗？</p>,
            onOk() {
                removeDataCall(idx);
            },
            onCancel() {
                destroyAllCall();
            },
        });
    };
     //销毁确认框
    const destroyAll = () => {
        Modal.destroyAll();
    }; 
    
    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };
  
  

    const tempTypeChange=(value)=>{
        form.setFieldsValue({ 'tempName': 0  })
        if(value == 0){
            setTempNames([]);
            setCateId(0)
        }else{
            setCateId(value)
            loadTempByCate(value).then((res)=>{
                setTempNames(res.data);
            })
        }
    }

    const tempNameChange=(value)=>{
        setTmpId(value)
    }
  
    const pdtNameChange=(value)=>{
        setPdtName(value)
    }

    const handleChange = (info) => {
          getBase64(info.file, (url) => {
            setLoading(false);
            setImageUrl(url);
          });
        
      };
    
  
    const handleCancel = () => {
        setPreviewOpen(false);
        setImageUrl(null);
    }

    
    const beforeUpload = (file) => {
        const formdata = new FormData();
        formdata.append('file',file);
        formdata.append('cateId',form.getFieldValue('tempType'));
        formdata.append('tempId',form.getFieldValue('tempName'));
        formdata.append('pdtName',adForm.getFieldValue('productName'));
        addProduct(formdata).then((res)=>{
                
          if(res.code !== 0){
              message.error(res.msg);
          }else{
              form.resetFields();
              adForm.resetFields();
              setCateId(0);
              setTmpId(0);
              setTempNames([]);
              message.info('添加成功');
              setPreviewImage(res.data.url);
              setPreviewOpen(true);
              setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
              getAllData(1,10,null,null);
          }
        })
       
        return false;
      };
   
    const showModal = () => {
        setAddModalVisible(true);
        console.log("showmodal----")
    };


    const addHandleOk = () => {
        setAddModalVisible(false);
        getAllData();
    };

    const addHandleCancel=()=>{
        setAddModalVisible(false);
    }
   
    const showImg=(record)=>{
        setPreviewImage(record.orginalImgUrl);
        setImageUrl(record.imgUrl);
        setPreviewOpen(true);
        setPreviewTitle(record.pdtName);
    }



    return (
        
        <div className="productList">
            <Modal
                visible={addModalVisible}
                title={"添加产品"}
                onOk={addHandleOk}
                onCancel={addHandleCancel}
                footer={null}
                >
            <Form form={adForm} name="addProduct" labelCol={{span: 4}}wrapperCol={{ span: 18,}} initialValues={ modelData.data}
                 autoComplete="off"
                >
                
                <Form.Item labelCol={{ offset: 3 }}label="产品名称" name="productName">
                    <Input onChange={pdtNameChange}  placeholder='请输入产品名称'/>                                       
                </Form.Item>
            
                <Form.Item labelCol={{offset: 3}} label="产品图片" name="productPng">
                <Upload
                    accept='.png,.jpg,.jpeg'
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    onChange={handleChange}
                >
                        <Button 
                        type='primary'
                        disabled={!cateId ||!tmpId||!pdtName}
                        icon={<PlusOutlined/>}
                    >
                        添加图片
                    </Button>
                </Upload>

                
                            
                </Form.Item>
                
                </Form>
                
            </Modal>
            <Modal visible={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                    <Input style={{color:'red'}} disabled={true} value={previewImage}></Input>
                    <img
                    alt="无法显示"
                    style={{
                        width: '100%',
                    }}
                    src={imageUrl}
                    />
                    
                </Modal>

            
           

            <div className="list_title">
                    <h3>产品列表</h3>
                    <Form
                        form={form}
                        layout="inline"
                        name="productForm"
                        initialValues={{
                            tempType:0,
                            tempName:0
                        }}
                    >
                            <Row gutter={[24, 16]}>
                                <Col span={8}>
                                <Form.Item
                                    labelCol={{
                                        offset:3,
                                        span: 7
                                    }}
                                    wrapperCol={{
                                        span:14
                                    }}
                                    label="模板类目"
                                    name='tempType'
                                >
                                    <Select onChange={tempTypeChange}>
                                        <Option value={0} key={0}>-请选择模板类目-</Option> 
                                        {tempCates.map(item=>
                                            <Option value={item.id} key={item.id}>{item.name}</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                                </Col>
                        

                                <Col span={8}>
                                    <Form.Item
                                        labelCol={{
                                            offset:1,
                                            span: 7
                                        }}
                                        wrapperCol={{
                                            span:16
                                        }}
                                        label="模板名称"
                                        name="tempName"
                                    >
                                        <Select  onChange={tempNameChange}>
                                            <Option key={0} value={0}>-请选择模板名称-</Option>
                                            {tempNames.map(item => (
                                                <Option key={item.id} value={item.id}>{item.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={3}>
                                    <Button className="sear" onClick={()=>getAllData()}>
                                            搜索
                                        </Button>
                                    
                                </Col>
                                <Col span={3}>
                                    <Button type="primary" className="xz" onClick={()=>showModal()} disabled={!cateId ||!tmpId}>
                                            新增
                                        </Button>
                                </Col>

                                </Row>
                            </Form>
                            

         
            </div>
            <Table
                rowKey="id"
                columns={columns}
                dataSource={data}
                bordered
                pagination={{
                    current: queryParams.current,
                    pageSize: queryParams.size,
                    total: total,
                    pageSizeOptions: ["10", "30", "50", "100"],
                    showSizeChanger: true,
                    showQuickJumper: true,
                    showTotal: (total) => `共 ${total} 条记录`,
                }}
                onChange={(pagination, sorter, extra)  => {
                    if (
                        pagination.current === queryParams.current &&
                        pagination.pageSize ===queryParams.size
                    )
                        return;
                    setQueryParams( { ...queryParams, current: pagination.current,size: pagination.pageSize});
                    getAllData(pagination.current, pagination.pageSize);
                }}
                scroll={{ x: 'max-content', y: 600 }}
            />
        </div>
    )

}

export default AddProduct;