import React from 'react'
import welcome from "../../assets/images/welcome.jpeg";
import './index.css'

const Home = () => {
	return (
		<div className="home card">
			<img src={welcome} alt="welcome" />
		</div>
	);
};

export default Home;