import React, { useState, useEffect,useRef } from 'react';
import './domainConfig.css'
import { Row, Col, Select,Button, Modal, Input, Checkbox, Form, Spin, message } from 'antd';
import Draggable from 'react-draggable';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { listDomainPath, doCreatePath, doDeletePath, grabWebSite,jumpWebSite,swithTarget,
    loadTempCategory,loadTempByCate,loadProductByTemp,generateTarget} from '../../api/domainConfig'
import { fromByteArray } from 'ipaddr.js';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { array } from 'bfj/src/events';




const DomainConfig = (props) => {

    const { Option } = Select;
    const [form] = Form.useForm();
    const [adForm] = Form.useForm()
    const [domainSubPaths,setDomainSubPaths] = useState([]);
    const [tempCates,setTempCates] = useState([]);
    const [tempNames,setTempNames] = useState([]);
    const [productNames,setProductNames] = useState([]);
    const [checkStatus,setCheckStatus] = useState({
        domainStatus: '',
        domainCreateStatus:''
    });
    const [loadings,setLoadings] = useState([]);
    const [iframeUrl,setIframeUrl] = useState('');
    const [urlPath,setUrlPath] = useState('');
    const [showDiv,setShowDiv] = useState(false);


    useEffect(() => {
        loadTempCategory().then(res=>{
             setTempCates(res.data);
        });
      }, []);


    const loadDomainPaths = (hide) => {
        let domainStr = form.getFieldValue('domainStr');
        if(!domainStr){
            message.warn('请输入域名');
            return;
        }
        listDomainPath({domain:domainStr})
        .then((res) => {
            if(res.code != 0){
                if(!hide){
                    message.error(res.msg);
                    setCheckStatus({...checkStatus ,domainStatus:'error'})
                }
            }else{
                if(!hide){
                    message.info('验证成功');
                    setCheckStatus({...checkStatus ,domainStatus:'success'})
                }
                setDomainSubPaths(res.data);
            }
        });
    }

    //验证
    const checkDomain = () => {
        loadDomainPaths();
    }
    const domainChange =(value)=>{
        form.setFieldsValue({pathList:''});
        setShowDiv(false);
        setUrlPath('');
        setIframeUrl('');
        setDomainSubPaths([])
    }
    const filterOption = (input, option) =>{
        return (option?.children ?? '').toLowerCase().includes(input.toLowerCase());
    }
    
    const pathChange = (value,event)=>{
        let selectId = form.getFieldValue('pathList');
        if(!selectId){
            setShowDiv(false);
            return;
        }
        let currentUrl = "https://" + form.getFieldValue('domainStr') + event.children;
        setShowDiv(true);
        setIframeUrl(currentUrl);
        setUrlPath(currentUrl);
    }

    //创建
    const createPath =()=>{
        let domainStr = form.getFieldValue('domainStr');
        let pathStr = form.getFieldValue('pathStr');
        if(!domainStr){
            message.error('请输入域名');
            return;
        }
        if(!pathStr){
            message.error('请输入要创建的目录');
            return;
        }
        doCreatePath({domain:domainStr,path:pathStr}).then((data)=>{
            
            if(data.code !== 0){
                message.error(data.msg);
                setCheckStatus({...checkStatus,domainCreateStatus:'error'})
            }else{
                message.info('创建成功');
                setCheckStatus({...checkStatus,domainCreateStatus:'success'})
                loadDomainPaths(true);
            }
        })
    }
    //删除
    const deletePath =()=>{
        let selectId = form.getFieldValue('pathList');
        if(!selectId ){
            message.error('请选择目录');
            return;
        }
        doDeletePath(selectId).then((data)=>{
            if(data.code !== 0){
                message.error(data.msg);
            }else{
                message.info('删除成功');
                form.setFieldsValue({pathList:''});
                loadDomainPaths(true);
            }
        })
    }
    //采集
    const grab = (index)=>{
        let selectId = form.getFieldValue('pathList');
        let targetPath = form.getFieldValue('targetPath');
        if(!selectId){
            message.error('请选择目录');
            return;
        }
        if( !targetPath){
            message.error('请输入要采集的网址');
            return;
        }
        
        setLoading(index,true);
        grabWebSite({recordId:selectId,targetPath:targetPath}).then((data)=>{
            if(data.code !== 0){
                message.error(data.msg);
            }else{
                message.info('采集成功');
            }
            setLoading(index,false);
        },err=> {
            setLoading(index,false);
        })
    }
    //生成跳转
    const jumpGenerate = (index)=>{
        let selectId = form.getFieldValue('pathList');
        let targetPath = form.getFieldValue('jumpTargetPath');
        if(!selectId){
            message.error('请选择目录');
            return;
        }
        if( !targetPath){
            message.error('请输入要跳转的网址');
            return;
        }
        
        setLoading(index,true);
        jumpWebSite({recordId:selectId,targetPath:targetPath}).then((data)=>{
            if(data.code !== 0){
                message.error(data.msg);
            }else{
                message.info('跳转生成成功');
            }
            setLoading(index,false);
        },err=> {
            setLoading(index,false);
        })
    }

    
    const setLoading = (idx,flag)=>{
        setLoadings(lds => {
            lds[idx] = flag // 这里数据引用地址的值同样被修改
            return [...lds] // 数据最外层的引用地址不同即可触发组件更新
              
        });
    }
    //切换
    const swithPage = (idx,type)=>{
        let selectId = form.getFieldValue('pathList');
        if(!selectId){
            message.error('请选择目录');
            return;
        }
       
        setLoading(idx,true);
        swithTarget({recordId:selectId,type:type}).then((data)=>{
            if(data.code !== 0){
                message.error(data.msg);
            }else{
                const url = JSON.parse(JSON.stringify(iframeUrl));
                setIframeUrl(url+'?' + new Date())
                message.info('切换成功');
            }
            setLoading(idx,false);
        },err=> {
            setLoading(idx,false);
        })
    }

    const tempTypeChange=(value)=>{
        adForm.setFieldsValue({ 'tempName': 0 , 'productName': 0 })
        if(value == 0){
            setTempNames([]);
        }else{
            loadTempByCate(value).then((res)=>{
                setTempNames(res.data);
                setProductNames([]);
                
            })
        }
    }

    const tempNameChange=(value)=>{
        console.log(adForm.getFieldValue('productName'))
        adForm.setFieldsValue({'productName': 0 })
        if(value == 0){
            setProductNames([]);
        }else{
            loadProductByTemp(value).then((res)=>{
                setProductNames(res.data);
            })
        }
    }
    //生成
    const generate=(index)=>{
        let selectId = form.getFieldValue('pathList');
        let productId = adForm.getFieldValue('productName');
        let adPath = adForm.getFieldValue('adPath');
        if(!selectId){
            message.error('请选择目录');
            return;
        }
        if(!productId){
            message.error('请选择产品');
            return;
        }
        if(!adPath){
            message.error('请输入广告链接地址');
            return;
        }
        adForm.validateFields();
        setLoading(index,true);
        generateTarget({recordId:selectId,productId:productId,adAddress:adPath}).then((res)=>{
            console.log(res)
           
            if(res.code !== 0){
                message.error('生成页面失败:'+res.msg);
            }else{
                message.info('生成成功');
            }
            setLoading(index,false);
        },err=>{
            setLoading(index,false);
        });
    }

    const copyPath = ()=>{
        
    }


    return (
        
        <div className="domainConfig">
            <div className="list_title">
                <h3>推广配置</h3>
            </div>
            <div className="panel-body">
                <Row>
                    <Col span={11} >
                        <h3 className='page-header'>安全页管理</h3>
                        <Form
                            form={form}
                            layout="inline"
                            name="safety"
                            initialValues={{
                                domainStr: props.location.state?.domain
                            }}
                            autoComplete="off"
                        >
                            <Row gutter={[8,16]}>
                                <Col span={16}>
                                    <Form.Item
                                        labelCol={{
                                            offset: 3
                                        }}
                                        label="操作域名"
                                        name='domainStr'
                                        onChange={domainChange}
                                        hasFeedback
                                        validateStatus={checkStatus.domainStatus}
                                    >
                                        <Input  placeholder='请输入域名'/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Button
                                        type="default"
                                        onClick={checkDomain}
                                        style={{color:'#fff',  backgroundColor: '#5cb85c' ,borderColor: '#4cae4c' ,width:'70px'}}
                                    >
                                        验证
                                    </Button>
                                </Col>

                                <Col span={16}>
                                    <Form.Item
                                        labelCol={{
                                            offset: 3
                                        }}
                                        label="创建目录"
                                        name="pathStr"
                                        hasFeedback
                                        validateStatus={checkStatus.domainCreateStatus}
                                        
                                    >
                                        <Input placeholder='请输入目录名' />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Button type="default" onClick={createPath}
                                        style={{color:'#fff',  backgroundColor: '#337ab7' ,borderColor: '#2e6da4',width:'70px' }}
                                    >
                                        创建
                                    </Button>
                                </Col>

                                <Col span={16}>
                                    <Form.Item
                                        labelCol={{
                                            offset: 3
                                        }}
                                        label="操作目录"
                                        name="pathList"
                                    >
                                    <Select 
                                        showSearch
                                        filterOption={filterOption}  
                                        onChange={pathChange} 
                                        placeholder="操作目录列表" 
                                    >
                                     {domainSubPaths.map(item => (
                                        <Option key={item.recordId} value={item.recordId}>{item.path}</Option>
                                     ))}
                                    </Select>                                         

                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Button  type="default" onClick={deletePath}
                                         style={{color:'#fff',  backgroundColor: '#d9534f' ,borderColor: '#d43f3a',width:'70px' }}
                                    >
                                        删除
                                    </Button>
                                </Col>
                                <Col span={16}>
                                    <Form.Item
                                        labelCol={{
                                            offset: 3
                                        }}
                                        label="采集生成"
                                        name="targetPath"
                                    >
                                        <Input placeholder='请输入采集网址' />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Button type="default" onClick={() => grab(0)} loading={loadings[0]}
                                         style={{color:'#fff', backgroundColor: '#5bc0de' ,borderColor: '#46b8da' ,width:'70px'}}
                                    >
                                        采集
                                    </Button>
                                </Col>
                                <Col span={16}>
                                    <Form.Item
                                        labelCol={{
                                            offset: 3
                                        }}
                                        label="跳转生成"
                                        name="jumpTargetPath"
                                    >
                                        <Input placeholder='请输入跳转网址' />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Button type="default" onClick={() => jumpGenerate(4)} loading={loadings[4]}
                                         style={{color:'#fff', backgroundColor: 'brown' ,borderColor: 'brown' ,width:'70px'}}
                                    >
                                        跳转
                                    </Button>
                                </Col>

                                <Col span={10} style={{textAlign:'right',height:'80px'}}>
                                <Button type="default" onClick={() => swithPage(1,1)} loading={loadings[1]}
                                         style={{color:'#fff',  backgroundColor: '#5cb85c' ,borderColor: '#4cae4c',fontSize:'18px',marginTop:'40px',height:'40px'}}
                                    >
                                        切换安全页
                                    </Button>
                                </Col>
                                <Col span={14}  style={{height:'80px'}}>
                                <Button type="default" onClick={() => swithPage(2,2)} loading={loadings[2]}
                                         style={{color:'#fff', backgroundColor: '#d9534f' ,borderColor: '#d43f3a' ,fontSize:'18px',marginTop:'40px',height:'40px'}}
                                    >
                                        切换着陆页
                                    </Button>
                                </Col>
                                
                            </Row>
                        </Form>

                        <h3 className='page-header'>着陆页管理</h3>

                        <Form
                            form={adForm}
                            layout="inline"
                            name="adForm"
                            initialValues={{
                                tempType:0,
                                tempName:0,
                            }}
                        >
                            <Row gutter={[8,16]}>
                                <Col span={16}>
                                <Form.Item
                                    labelCol={{
                                        offset: 3
                                    }}
                                    label="模板类目"
                                    name='tempType'
                                    rules={[{  required: true}]}
                                >
                                    <Select onChange={tempTypeChange}>
                                        <Option value={0} key={0}>-请选择模板类目-</Option> 
                                        {tempCates.map(item=>
                                             <Option value={item.id} key={item.id}>{item.name}</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Button
                                        type="default"
                                        onClick={() => generate(3)} loading={loadings[3]}
                                        style={{color:'#fff',  backgroundColor: '#337ab7' ,borderColor: '#2e6da4' ,width:'70px'}}
                                    >
                                        生成
                                    </Button>
                                </Col>

                                <Col span={16}>
                                    <Form.Item
                                        labelCol={{
                                            offset: 3
                                        }}
                                        label="模板名称"
                                        name="tempName"
                                        rules={[{  required: true}]}
                                    >
                                        <Select  onChange={tempNameChange}>
                                            <Option key={0} value={0}>-请选择模板名称-</Option>
                                            {tempNames.map(item => (
                                                <Option key={item.id} value={item.id}>{item.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                               

                                <Col span={16}>
                                    <Form.Item
                                        labelCol={{
                                            offset: 3
                                        }}
                                        label="产品名称"
                                        name="productName"
                                        rules={[{  required: true}]}
                                    >
                                    <Select >
                                        <Option key={0} value={0}>-请选择产品名称-</Option>
                                        {productNames.map(item => (
                                             <Option key={item.id} value={item.id}>{item.name}</Option>
                                        ))}
                                    </Select>                                         

                                    </Form.Item>
                                </Col>
                                <Col span={16}>
                                    <Form.Item
                                        labelCol={{
                                            offset: 3
                                        }}
                                        label="广告链接"
                                        name="adPath"
                                        rules={[
                                            {  required: true,},
                                            {type: 'url', warningOnly: true, },
                                            {type: 'string', min: 6,}
                                        ]}
                                    >
                                        <Input allowClear placeholder='请输入完整的广告链接' />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Col>

                    <Col span={13}>
                        <div hidden={!showDiv} style={{float: 'right',marginBottom:10}}>
                            <a href={urlPath} style={{fontStyle:'italic', marginRight: 10, color: '#000', fontSize: 18,textDecorationLine:'underline'}} target="_blank">{urlPath}</a>
                            
                            <CopyToClipboard text={urlPath} onCopy={()=>{message.success('复制成功')}}>
                            <Button 
                                style={{color:'#fff',  backgroundColor: '#337ab7' ,borderColor: '#2e6da4' }}
                                onClick={copyPath} 
                            >复制</Button>
                            </CopyToClipboard>
                        </div>
                        <iframe src={iframeUrl} height="110%" id="iframe" width="100%"  sandbox="allow-same-origin allow-scripts allow-popups allow-forms">

                        </iframe>
                    </Col>
                </Row>
            </div>
        </div>
    )

}

export default DomainConfig;