import './index.css'
import { useEffect } from 'react'
import { message } from 'antd'
import {withRouter} from 'react-router-dom'
import HeaderBar from '../components/header/header'
import Aside from '../components/aside/aside'
import DomainList from '../pages/domainList/domainlist'
import DomainConfig from '../pages/domainList/domainConfig'
import AddProduct from '../pages/domainList/addProduct'
import PixelList from '../pages/pixelList/pixellist'
import UserList from '../pages/userManage/userlist'
import Notfound from '../pages/notfound/notfound'
import {Route,Switch,Redirect} from 'react-router-dom'
import {get} from '../api/index'
import SubDomainList from '../pages/domainList/subdomainlist'
import Home from '../pages/home'

function Layouts(props){

    //记录当前用户第一个全线路有path
    // var redminPath;

    useEffect(()=>{
        isLogin()
    },[])

    //验证是否登录，若未登录跳回登录页
    async function isLogin(){

        await get("/admin/user/sessionGet").then((data)=>{
            if(data.code !== 0){
                //若未登录，跳到登录页
                message.info('请先登录吧！');
                props.history.push('/login')
            }
            
        },(err)=>{
            message.error('系统异常，请重新登录');
            props.history.push('/login')
        })
        
    }


    return (
        <div className="admin_box">
            <div className="admin_top_box">
                <HeaderBar></HeaderBar>
            </div>
            <div className="admin_bottom_box">
                    <div className="admin_left">
                        <Aside></Aside>
                    </div>
                    <div className="admin_right">
                        {/*  Switch排他性匹配 */}
                        <Switch>
                            {/* exact严格模式 ,Redirect重定向*/}
                            <Redirect exact={true} from="/layouts" to="/layouts/home"></Redirect>
                            <Route path="/layouts/home" component={Home}/>   
                            <Route path="/layouts/supdomainlist" component={DomainList}/>    
                            <Route path="/layouts/domainlist" component={SubDomainList}/>    
                            <Route path="/layouts/domainConfig" component={DomainConfig}/>
                            <Route path="/layouts/pixellist" component={PixelList}/>   
                            <Route path="/layouts/userlist" component={UserList}/> 
                            <Route path="/layouts/addProduct" component={AddProduct}/> 
                            {/* 一定能匹配到 */}
                            <Route component={Notfound}></Route>
                        </Switch>
                    </div>
            </div>
        </div>
    )
}

//高阶组件，获取路由上下文
export default withRouter(Layouts)