import {get,post,remove} from './index'


//查询
export function listDomainPath(params){
    //无参数，查询所有数据 ,有参则按照参数查找
    return get('/promotion/list',params)
}

//添加
export function doCreatePath(params) {
    //params 添加的数据
    return post("/promotion/add",params);
}

//删除(通过id删除)
export function doDeletePath(params) {
    return remove('/promotion/delete',params)
}

//采集
export function grabWebSite(params) {
    return post('/promotion/grab',params)
}

//跳转
export function jumpWebSite(params) {
    return post('/promotion/generateJump',params)
}

//切换
export function swithTarget(params) {
    return post('/promotion/switchTarget',params)
}
//加载模板类目
export function loadTempCategory(){
    return get('/support/category/list')
}
//加载模板
export function loadTempByCate(cateId){
    return get('/support/template/list/'+cateId);
}
//加载产品
export function loadProductByTemp(tempId){
    return get('/support/product/list/'+tempId);
}

export function generateTarget(params){
    return post('/promotion/generateTarget',params);
}

export function addProduct(params){
    //无参数，查询所有数据 ,有参则按照参数查找
    return post('/support/addProduct',params,{headers: {'content-Type': 'multipart/form-data'}})
}

export function getProductList(params){
    return get('/support/product/page',params)
}

export function removeProdcut(params){
    return remove('/support/product/delete',params)
}