import { Button, DatePicker, Form, Input ,message} from 'antd';
import { updateDomainExpire } from "../../api/domainlist";
import moment from "moment";

const ExpireUpdateForm = ({id,callback}) => {

  const [form] = Form.useForm();

  const onFinish = (values) => {

    values.id = id;
    values.expireTime = moment(values.expireTime).format("YYYY-MM-DD");
    updateDomainExpire(values).then((data)=>{
            
      if(data.code !== 0){
          message.error(data.msg);
      }else{
          form.resetFields()
          message.info('修改成功');
          callback && callback()
      }
    })

   
    console.log('Success:', values);
  };
 
  return (
        <Form
          form={form}
          name="expireUpdate"
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 12,
          }}
          style={{
            maxWidth: 600,
          }}
          onFinish={onFinish}
          
          autoComplete="off"
        >
          <Form.Item
            label="到期时间"
            name="expireTime"
            rules={[
              {
                required: true,
                message: '请选择到期时间!',
              },
            ]}
          >
            <DatePicker />
          </Form.Item>
        
          <Form.Item
            wrapperCol={{
              offset: 18,
              span: 16,
            }}
          >
            <Button type="primary"  htmlType="submit">
              保存
            </Button>
          </Form.Item>
        </Form>
  )
};
export default ExpireUpdateForm;