import { Button, Form, Input ,message} from 'antd';
import { addDomain } from "../../api/domainlist";

const AddDomainForm = ({callback}) => {

  const [form] = Form.useForm();

  const onFinish = (values) => {

    addDomain(values).then((data)=>{
            
      if(data.code !== 0){
          message.error(data.msg);
      }else{
          form.resetFields()
          message.info('添加成功');
          callback && callback()
      }
    })

   
    console.log('Success:', values);
  };
 
  return (
        <Form
          form={form}
          name="addDomain"
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 12,
          }}
          style={{
            maxWidth: 600,
          }}
          onFinish={onFinish}
          
          autoComplete="off"
        >
          <Form.Item
            label="域名"
            name="domain"
            rules={[
              {
                required: true,
                message: '请输入域名!',
              },
            ]}
          >
            <Input />
          </Form.Item>
        
          <Form.Item
            wrapperCol={{
              offset: 18,
              span: 16,
            }}
          >
            <Button type="primary"  htmlType="submit">
              保存
            </Button>
          </Form.Item>
        </Form>
  )
};
export default AddDomainForm;