import React, { useEffect, useState } from'react';
import { Button, Form, Input ,Select,message} from 'antd';
import { updateDomainAssignUser } from "../../api/domainlist";
import { listUserAll } from "../../api/userlist";
const { Option } = Select;

const AssignUserUpdateForm = ({assignData,callback}) => {

  const [assignUserForm] = Form.useForm();
  const [userDatas,setUserDatas] = useState([]);


  useEffect(() => {
    console.log(assignData);
    listUserAll().then(res=>{
      setUserDatas(res.data);
      assignUserForm.setFieldsValue({
        assignUserIdList:assignData.userIds
      });
    });
    
  }, [assignData]);

  const onFinish = (formValues) => {
    console.log(formValues);
    formValues.id = assignData.id;
    
    updateDomainAssignUser(formValues).then((data)=>{
            
      if(data.code !== 0){
          message.error(data.msg);
      }else{
        assignUserForm.resetFields()
          message.info('修改成功');
          callback && callback()
      }
    })
  };
 
  const userChange=(values,options)=>{
    console.log(values,options);
    assignUserForm.setFieldsValue({
      assignUserNameList:options.map(item=>item.children)
    });
  }

  return (
        <Form
          form={assignUserForm}
          name="assigns"
          labelCol={{ span: 6}}
          wrapperCol={{  span: 12}}
          style={{  maxWidth: 600}}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="人员"
            name="assignUserIdList"
            rules={[
              {
                required: true,
                message: '请选择人员!',
              },
            ]}
          >
             <Select 
                allowClear
                mode="multiple"
                onChange={userChange}
                placeholder="请选择人员"
                >
                <Option value={0} key={-1}>-请选择人员-</Option> 
                {userDatas.map(item=>
                    <Option value={item.id} key={item.id}>{item.nickname}</Option>
                )}
            </Select>
          </Form.Item>
          <Form.Item
            hidden={true}
            label="人员名称"
            name="assignUserNameList"
            // rules={[
            //   {
            //     required: true,
            //     message: '请选择人员!',
            //   },
            // ]}
          >
             <Input/>
          </Form.Item>
          <Form.Item
            wrapperCol={{
              offset: 18,
              span: 16,
            }}
          >
            <Button type="primary"  htmlType="submit">
              保存
            </Button>
          </Form.Item>
        </Form>
  )
};
export default AssignUserUpdateForm;