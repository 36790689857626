import React from 'react'
import {loginAdmin} from '../../api/login'
import { message } from 'antd';
import style from './emaillogin.module.css'
import { onLogin } from '../../api/cookie';


export default class Emaillogin extends React.Component{

    constructor(props){
        super()
        this.state = {
            loginobj:{
                username:"",
                password:""
            }
       }
    }


    //登录框双向绑定
    changeval=(e)=>{
        this.setState({
            loginobj:{
                ...this.state.loginobj,
                [e.target.name]:e.target.value
            }
        })
    }

    //登录
    async tologin(){
        let data = await loginAdmin(this.state.loginobj);
        console.log(data);
        if(data && data.code == 0  ){
            //查到数据,存储token
            message.info('登录成功');
            
            onLogin(data);
            localStorage.setItem('nickname',data.nickname);
            this.props.history.push('/layouts')
        }else{
            //未查到数据
            
            if(data){
                message.error('登录失败:'+data.msg );
            }else{
                message.error('登录失败:');
            }
        } 
    }
  
    render(){
        return (
            <table className={style.emaillogin}>
                <tbody>
                    <tr className={style.title}>
                        <td colSpan="2">
                            推广管理系统
                        </td>
                    </tr>
                    <tr>
                        <td>
                            用户名:
                        </td>
                        <td>
                            <input name="username" onChange={this.changeval} value={this.state.loginobj.username} type="input" placeholder="请输入用户名" />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            密码:
                        </td>
                        <td>
                            <input name="password" onChange={this.changeval} value={this.state.loginobj.password} type="password" placeholder="请输入密码" />
                        </td>
                    </tr>
                    <tr className={style.logined}>
                        <td colSpan="2">
                            <button onClick={()=>this.tologin()}>登录</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    }
}