import {get,post,remove,update} from './index'



//新增
export function addDomain(params){
    //无参数，查询所有数据 ,有参则按照参数查找
    return post('/domain/add',params)
}

export function updateDomainExpire(params){
    //无参数，查询所有数据 ,有参则按照参数查找
    return post('/domain/updateExpireTime',params)
}
export function updateDomainAssignUser(params){
    //无参数，查询所有数据 ,有参则按照参数查找
    return post('/domain/updateAssign',params)
}
//查询
export function getDomainList(params){
    //无参数，查询所有数据 ,有参则按照参数查找
    return get('/domain/page',params)
}

//删除(通过id删除)
export function removeDomainPath(params) {
    //params  要删除数据的id
    return remove('/domain/delete',params)
}



