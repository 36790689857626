import {post} from './index'
import {get} from './index'



export function logout(){
    get("/admin/user/logout");
}

//登录
export function loginAdmin(params){
    //登录，参数为用户名和密码
    let loginUser =  login('/admin/user/login',params);
    return loginUser;
}

export function login(url,params){

    let queryStr = JSON.stringify(params)
    return new Promise(function(resolve,reject){
        
        post(url,queryStr,{headers: {'Content-Type': 'application/json'}})
        .then(res=>{
            if(res.data){
                //查到数据
                resolve({
                    code: res.code,
                    msg: res.msg,
                    level: res.data.level,
                    username: res.data.username,
                    nickname:res.data.nickname
                });
            }else{
                resolve(res)
            }
        });
    });
}


