import React from "react";
import "./domainlist.css";
import {  Button,Form, Table, Modal, Input,Space, Row, Col,message} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { getDomainList,removeDomainPath } from "../../api/domainlist";
import  AddDomainForm  from "./addDomain";
import ExpireUpdateForm from "./expireUpdate";
import AssignUserUpdateForm from "./assignUserUpdate";


export default class DomainList extends React.Component {

    
    constructor(props) {
        super();
        this.state = {
            columns: [
                { title: "序号",width:100, dataIndex: "id"},
                { title: "域名", dataIndex: "domain"},
                { title: "分配人员", dataIndex: "assignUserNames"},
                { title: "到期时间", dataIndex: "expireTime"},
                { title: "创建时间",width:200, dataIndex: "createTime" },
                { title: "操作",width: 450, key: "action",
                    render: (text, record, index) => (
                        <>
                            <Button style={{color:'rgb(93 65 126)'}} type="link" onClick={() => {
                                this.setState({
                                    expireTimeModelShow:true,
                                    index: record.id
                                })
                                
                            }}>
                                修改到期时间
                            </Button>
                            <Button style={{color:'rgb(223 69 152)'}} type="link" onClick={() => {
                                console.log(record)
                                this.setState({
                                    assignData:{
                                        id:record.id,
                                        userIds: record.assignUserIdList
                                    },
                                    assignModelShow:true,
                                })
        
                            }}>
                                分配人员
                            </Button>
                            <Button style={{color:'rgb(242 39 59)'}} type="link" onClick={() => this.showConfirm(index)}>
                                删除
                            </Button>
                            <Button type="link" onClick={() => this.redirctConfig(record.domain)}>
                                去配置
                            </Button>
                        </>
                    ),
                },
            ],
            data: [],

            //当前修改的哪条数据
            index: 0,

            //查询条件，双向绑定
            findParams: {
                domain: "",
            },

            top: "topLeft",
            bottom: "bottomRight",
            model:{
                visible:false,
                loading: true
            },
            expireTimeModelShow:false,
            assignModelShow:false,
            domainStr:"",
            assignData:{},
            ipStr:""
            
        };
        this.otherInfo = {
            current: 1,
            size: 10,
            total: 0,
        };
        
    }


    removeData = (idx) => {
        //删除
        console.log("remove里的idx", idx);
        removeDomainPath(this.state.data[idx].id).then((data) => {
            this.getAllData();
        });
    };

    updateData = (idx) => {
        //显示修改框，并更新当前修改的index及双向绑定的数据
        this.setState({
            isshowUpdate: "block",
            index: idx.id,
            modelData: idx,
        });
    };

    redirctConfig = (domain) =>{
        this.props.history.push('/layouts/domainConfig',{domain: domain})
    }

    //获取所有数据
    getAllData = () => {
        getDomainList({
            current: this.otherInfo.current,
            size: this.otherInfo.size,
            domain: this.state.findParams.domain,
        }).then((res) => {
            if(res.code !== 0){
                message.error(res.msg);
                return;
            }
            this.otherInfo.total = res.data.total;
            res.data.list.forEach((item, idx) => {
                item.key = idx;
            });
            this.setState({
                data: res.data.list,
            });
        });
    };

    //渲染完毕即获取数据
    componentDidMount() {
        this.getAllData();
    }


    //双向绑定（查询条件）
    changeFind = (ev) => {
        this.setState({
            findParams: {
                ...this.state.findParams,
                [ev.target.name]: ev.target.value,
            },
        });
    };



    //销毁确认框
    destroyAll = () => {
        Modal.destroyAll();
    };

    showConfirm = (idx) => {
        let that = this;
        const { confirm } = Modal;
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: <p>确定删除吗？</p>,
            onOk() {
                that.removeData(idx);
            },
            onCancel() {
                that.destroyAll();
            },
        });
    };


    showModal = () => {
        this.setState({
            model:{
                ...this.state.model,
                visible:true
            }
        })
    };
    handleCancel = () => {
        this.setState({
            model:{
                ...this.state.model,
                visible:false
            }
        })
    };
    handleOk = () => {
        
        this.setState({
            model:{
                ...this.state.model,
                visible:false
            }
        })
        this.getAllData();
    };

    handleExpireOk = () => {
        
        this.setState({
            expireTimeModelShow : false
        })
        this.getAllData();
    };
    handleExpireCancel = () => {
        this.setState({
            expireTimeModelShow : false
        })
    };

    handleAssignOk = () => {
        
        this.setState({
            assignModelShow : false
        })
        this.getAllData();
    };
    handleAssignCancel = () => {
        this.setState({
            assignModelShow : false
        })
    };

    render() {
        return (
            
            <div className="domainlist">
                
                
                <Modal
                    visible={this.state.model.visible}
                    title="添加域名"
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}
                    >
                
                  <AddDomainForm callback={()=>this.handleOk()}/>
                </Modal>

                <div className="list_title">
                    <h3>域名列表</h3>
                    <input
                        type="text"
                        name="domain"
                        value={this.state.findParams.domain}
                        onChange={this.changeFind}
                        id="sear_ipt"
                        placeholder="请输入域名"
                    />

                    <button className="sear" onClick={this.getAllData}>
                        搜索
                    </button>
                    <button type="primary" className="xz" onClick={this.showModal}>
                        新增
                    </button>
                    
                </div>
                <p></p>
                <Table
                    rowKey="id"
                    type="small"
                    columns={this.state.columns}
                    dataSource={this.state.data}
                    bordered
                    pagination={{
                        current: this.otherInfo.current,
                        pageSize: this.otherInfo.size,
                        total: this.otherInfo.total,
                        pageSizeOptions: ["10", "30", "50", "100"],
                        showSizeChanger: true,
                        showQuickJumper: false,
                        showTotal: (total) => `共 ${total} 条记录`,
                    }}
                    onChange={(pagination) => {
                        if (
                            pagination.current === this.otherInfo.current &&
                            pagination.pageSize === this.otherInfo.size
                        )
                            return;
                        this.otherInfo = {
                            ...this.otherInfo,
                            current: pagination.current,
                            size: pagination.pageSize,
                        };
                        this.getAllData();
                    }}
                />

                <Modal
                    visible={this.state.expireTimeModelShow}
                    title="修改到期时间"
                    onOk={this.handleExpireOk}
                    onCancel={this.handleExpireCancel}
                    footer={null}
                    >
                    <ExpireUpdateForm id = {this.state.index} userList={this.state.userList} callback={()=>this.handleExpireOk()} />
                </Modal>

                <Modal
                    visible={this.state.assignModelShow}
                    title="分配人员"
                    onOk={this.handleAssignOk}
                    onCancel={this.handleAssignCancel}
                    footer={null}
                    >
                    <AssignUserUpdateForm assignData = {this.state.assignData} callback={()=>this.handleAssignOk()} />
                </Modal>

            </div>
        );
    }
}
